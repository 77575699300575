import React, { useLayoutEffect, useState } from 'react';
import { InlineWidget } from 'react-calendly';
import { DidomiModal, DidomiModalContent } from '@didomi/ui-atoms-react';
import { useActiveOrganization, useUserProfile } from '@didomi/utility-react';

const CalendlyWidgetModal = ({ isOpen, toggleOpen }) => {
  const [userProfile, isLoadingUserProfile] = useUserProfile();
  const { organization } = useActiveOrganization();
  const [url, setUrl] = useState('');

  useLayoutEffect(() => {
    /* istanbul ignore if */
    if (isLoadingUserProfile || !organization) return;

    const params = new URLSearchParams();
    const baseUrl = organization.self_register
      ? 'https://calendly.com/demo-didomi/didomi-dsar-demo-csl'
      : 'https://calendly.com/didomi-customers/didomi-automated-privacy-request-introduction';
    params.set('name', /* istanbul ignore next */ userProfile?.name ?? '');
    params.set('email', /* istanbul ignore next */ userProfile?.email ?? '');
    if (organization.self_register) {
      params.set('a2', /* istanbul ignore next */ organization?.website ?? '');
    }
    setUrl(`${baseUrl}?${params}`);
  }, [userProfile, organization, isLoadingUserProfile]);

  return (
    <DidomiModal isOpen={isOpen} onOpenChange={/* istanbul ignore next */ e => toggleOpen(e.detail)} data-testid="calendly-modal">
      <DidomiModalContent style={{ minWidth: 366 }}>
        <InlineWidget
          styles={{ height: 500, marginBottom: 32 }}
          iframeTitle="Didomi Calendly Widget"
          pageSettings={{
            backgroundColor: 'ffffff',
            hideEventTypeDetails: true,
            hideLandingPageDetails: true,
            primaryColor: '265973',
            textColor: '4d5055',
            hideGdprBanner: true,
          }}
          url={url}
        />
        <p style={{ fontSize: '12px', lineHeight: '1.2rem', color: '#1D4153' }}>
          DSAR TEXT{' '}
          <a style={{ fontSize: 'inherit', textDecoration: 'underline' }} rel="noopener noreferrer" target="_blank" href="https://preferences.didomi.io/en/">
            Privacy Requests
          </a>
          . To learn more about our use of your data, you can visit our{' '}
          <a style={{ fontSize: 'inherit', textDecoration: 'underline' }} rel="noopener noreferrer" target="_blank" href="https://privacy.didomi.io/en/">
            Privacy Center
          </a>{' '}
          or contact our{' '}
          <a style={{ fontSize: 'inherit', textDecoration: 'underline' }} href="mailto:dpo@didomi.io">
            DPO
          </a>
          .
        </p>
      </DidomiModalContent>
    </DidomiModal>
  );
};

export { CalendlyWidgetModal };
