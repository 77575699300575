import React, { useState } from 'react';
import { DidomiButton, DidomiChip } from '@didomi/ui-atoms-react';
import { PromotionalPageBanner } from '@components';
import { CalendlyWidgetModal } from '@modals';
import { useActiveOrganization, useGTM } from '@didomi/utility-react';

/**
 * Promotional Page
 */
const PromotionalPage = (): JSX.Element => {
  const [showCalendly, setShowCalendly] = useState(false);
  const { organization } = useActiveOrganization();
  const { pushEventToDataLayer } = useGTM();

  const handleClick = () => {
    pushEventToDataLayer('dsar-book-demo');

    if (organization.self_register) {
      window.open('https://www.didomi.io/schedule-demo', '_blank');
    } else {
      setShowCalendly(true);
    }
  };

  return (
    <div className="h-full w-full bg-light-blue flex justify-center px-l">
      <CalendlyWidgetModal isOpen={showCalendly} toggleOpen={setShowCalendly} />
      <div className="w-5/12 pl-m flex flex-col justify-center min-w-[445px] max-w-[400px]">
        <DidomiChip variation="basic-outline" label="New product" />
        <div className="mt-s uppercase text-body-big font-semibold text-primary-blue-4">Automated Privacy Request Management</div>
        <h1 className="mt-xxs h1-catchline-medium text-primary-blue-6">Privacy request simplified, for everyone</h1>
        <div className="mt-s font-sans text-body-normal text-primary-blue-6 mb-xxs">
          <strong>Data privacy is more than simply adhering to regulatory requirements;</strong>
          <br />
          it is also about providing all necessary information to your users and empowering them to exercise their rights while maintaining a positive user experience. With Didomi
          as your partner, managing, fulfilling, and responding back to the user request on time hasn&lsquo;t been easier.
        </div>
        <div className="mt-s">
          {/* wrap button in a div because the clickHandler is placed on the host element which gets stretched by flex-col */}
          <DidomiButton data-tracking="dsar-start-booking-a-demo" data-testid="book-demo-btn" onClick={() => handleClick()} data-skeleton>
            Book a demo
          </DidomiButton>
        </div>
      </div>
      <div className="flex-1 h-full items-center justify-start xl:justify-center! hidden lg:flex! overflow-hidden -mr-l">
        <PromotionalPageBanner className="min-w-[685px] max-w-[900px]" />
      </div>
    </div>
  );
};

export { PromotionalPage };
