import React, { useCallback, useEffect } from 'react';
import { PromotionalPage } from '@pages';
import { useGTM, useUserProfile } from '@didomi/utility-react';

const App = () => {
  const [userProfile] = useUserProfile();
  const { pushEventToDataLayer } = useGTM();

  /* istanbul ignore next */
  const onSpaErrorHandler = useCallback(
    error => {
      /* istanbul ignore next */
      const {
        detail: { error: errorMessage },
      } = error as CustomEvent<{ error: string }>;

      /* istanbul ignore next */
      pushEventToDataLayer && pushEventToDataLayer('error', { selection_value: errorMessage });
    },
    [pushEventToDataLayer],
  );

  useEffect(() => {
    if (userProfile?.self_register && pushEventToDataLayer) {
      pushEventToDataLayer('product-version', {
        product: 'self-service',
        page_path: 'dsar-preview',
      });
    }

    window.addEventListener('on-spa-error', onSpaErrorHandler);

    return function cleanup() {
      window.removeEventListener('on-spa-error', onSpaErrorHandler);
    };
  }, [userProfile, pushEventToDataLayer, onSpaErrorHandler]);

  return <PromotionalPage />;
};

export { App };
